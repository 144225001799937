$(function(){
	/////// 
	// Prevent transitions before DOM load
	$(window).load(function() {
		$("body").removeClass("preload");
	});
	
	// Menumobile - http://mmenu.frebsite.nl/
	$('#mmenu').mmenu({
		"slidingSubmenus": false,
		"extensions": [
			"pagedim-black",
            "multiline",
            "theme-white",
            "border-full",
		],
		"offCanvas": {
			"position": "right",
			"zposition": "front"
		},
		navbar: {
			title: null
		},
		"navbars": [
            {
            	"position": "top",
            	"content": [
            		'<a href="'+siteURI+'" class="mmenu-logo"><img src="'+siteURI+'/images/logo.png" alt="Logo" /></a>',
            		"close",
            	]
            }
         ]
	});
	parallax($('article section.banner img'), $('article section.banner'));	

	if(!isMobile()){
		$(window).on('scroll load', function(event) {
			if($('.nos-specialisations .specialisations,.triangles .desktop').length){
				$('.nos-specialisations .specialisations div,.triangles .desktop .triangle').each(function(i){	

					var speed = ($('.nos-specialisations').length) ? 125 : 200;

					if($(this).isOnScreen() === true && !($(this).hasClass('completed'))){
						var item  = $(this);
						item.css('opacity',0);
						item.delay(speed * i).animate({opacity:1},400);
						item.addClass('completed').removeClass('item');		
					}
				});	
			}
		});
	} else {
		$('.nos-specialisations .specialisations div,.triangles .desktop .triangle').css("opacity",1);
	}

	$('#mmenu .mm-subopen + a').click(function(e){
		e.preventDefault();
		$(this).prev().trigger('click');
	});
	$('#mmenu .search-field').attr('placeholder', '').focusout(function(){
		if($(this).val() !== ''){
			$(this).closest('form').submit();
		}
	});
		
	// form validation
	$('form').submit(function(e) {
		e.preventDefault();
		var valid = true;
		var loader = $(this).find('.loading');
		var log = $(this).find('.error-log');
		var form = $(this);
		var data = {
			fields: {}
		};
		
		loader.show();
		log.text('');
		$(this).find('field').removeClass('invalid');
		
		$(this).find('.field').each(function(i, input) {
			data['fields'][$(this).attr('name')] = {
				name: $(this).attr('placeholder'),
				val: $(this).val()
			}
			
			if($(this).hasClass('required')){
				if($(this).val() == ''){
					valid = false;
					$(this).addClass('invalid').focus(function() {
						$(this).removeClass('invalid');
					});
				}
			}
			
			if($(this).attr('type') == 'email'){
				if(!validateEmail($(this).val())){
					valid = false;
					$(this).addClass('invalid').focus(function() {
						$(this).removeClass('invalid');
					});
				}
			}
		});
		if(!$(this).hasClass('english-form')){
			if(valid){
				log.text('Traitement en cours...').show();
				data['sujet'] = 'Formulaire de contact - MB recrutement';
				
				$.post(siteURI+'/inc/mail/send.php', data, function(res){
					if(res){
						loader.hide();
						log.text('Merci pour votre message. Il a bien été envoyé.');
						form.find('.field').val('');
						
					}else{
						loader.hide();
						log.text('Une erreur s’est produite lors de l’envoi de votre message. Veuillez essayer à nouveau plus tard.');
					}
				}, 'json');
				
			}else{
				loader.hide();
				log.text('Un ou plusieurs champs contiennent une erreur. Veuillez vérifier et essayer à nouveau.');
			}
		}else{
			if(valid){
				log.text('Processing...').show();
				data['sujet'] = 'Contact form - MB recrutement';
				
				$.post(siteURI+'/inc/mail/send.php', data, function(res){
					if(res){
						loader.hide();
						log.text('Thank you for your message. It has been sent.');
						form.find('.field').val('');
						
					}else{
						loader.hide();
						log.text('An error occurred while sending your message. Please try again later.');
					}
				}, 'json');
				
			}else{
				loader.hide();
				log.text('One or more fields contain an error. Please check and try again.');
			}
		}
	});
});

/*************/
/* ANIMATION */
/*************/
// add class .toAnimate to html element with a animate.css effect class
// when on screen, the animation will execute
$(document).ready(function(){
	$(window).on('scroll load', function(event) {
		$('.toAnimate').each(function(index, el) {
			if($(this).isOnScreen() === true){
				$(this).addClass('animated').removeClass('toAnimate');
				
				var elem_class = $(this).attr('class');
				var pos = elem_class.search('animS_');
				
				if(pos !== -1){
					var anim_name = elem_class.substring(pos, elem_class.indexOf(" ", pos));
					$(this).removeClass(anim_name);
					
					anim_name = anim_name.replace('animS_', '');
					
					$(this).addClass(anim_name);
				}
			}
		});
	});
});